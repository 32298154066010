$themeBlack: #3c3c3c;

.menu {
  padding: 150px 6.25% 150px calc(6.25% + 150px);
  @media (max-width: 767px) {
    padding: 100px 4.6875vw;
  }
  position: relative;
  counter-reset: portfolioCounter;
  overflow: hidden;
  &.complete {
    .menu__item:before {
      opacity: 1;
    }
  }
  .menu__item {
    color: black;
    &:hover {
      text-decoration: none;
      // color: #f5efe1;
    }
    position: relative;
    display: grid;
    grid-template-columns: auto auto 1fr;
    grid-template-areas:
      ". subtitle ."
      "id title .";
    padding: 0.5rem 1rem;
    &:before {
      opacity: 0;
      transition: opacity 0.3s ease-in;
      counter-increment: portfolioCounter;
      content: counters(portfolioCounter, ".", decimal-leading-zero);
      grid-area: id;
      font-family: "Marion";
      font-size: 2vw;
      margin-right: 10px;
      @media (max-width: 767px) {
        font-size: 15px;
        margin-right: 5px;
      }
    }
    .menu__item-text {
      grid-area: title;
      position: relative;
      overflow: hidden;
      .menu__item-textinner {
        display: block;
        font-size: 60px;
        white-space: nowrap;
        line-height: 1;
        font-family: "Marion";
        transform: translateY(100%);
        @media (max-width: 767px) {
          font-size: 30px;
        }
      }
    }
    .menu__item-sub {
      grid-area: subtitle;
      text-align: right;
      text-transform: uppercase;
      font-weight: bold;
      white-space: nowrap;
      opacity: 0;
      transform: translateY(1rem);
      transition: transform 0.3s, opacity 0.3s;
    }
    &:hover {
      .menu__item-sub {
        opacity: 1;
        transform: translateY(0);
        @media (max-width: 767px) {
          opacity: 0;
        }
      }
    }
    .hover-reveal {
      // mix-blend-mode: difference;
      position: absolute;
      z-index: -1;
      width: 400px;
      height: 300px;
      top: 0;
      left: 0;
      pointer-events: none;
      opacity: 0;
    }

    .hover-reveal__inner {
      overflow: hidden;
    }

    .hover-reveal__inner,
    .hover-reveal__img {
      width: 100%;
      height: 100%;
      position: relative;
    }

    .hover-reveal__img {
      background-size: cover;
      background-position: 50% 50%;
    }
  }
}

.distort {
  position: absolute;
  pointer-events: none;
  will-change: transform;
  &__img {
    opacity: 0;
  }
}
