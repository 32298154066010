.description {
  padding: 64px 6.25%;
  max-height: 100vh;
  img {
    width: 100%;
  }
  &__row {
    display: flex;
  }
  &__col {
    flex: 1;
    padding: 3rem;
    @media (max-width: 767px) {
      padding: 0;
    }
    h3 {
      margin: 1rem;
      text-align: center;
    }
  }
}
