$themeWhite: #f5f5f5;
$themeBlack: #3c3c3c;

@keyframes textFill {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: $themeWhite;
  z-index: 99999;
  .loading__backgroundLayer {
    background: $themeWhite;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  .loading__layer {
    position: relative;
    background: $themeBlack;
    height: 100%;
    overflow: hidden;
  }
  .loading__bar {
    position: absolute;
    height: 10px;
    background: $themeWhite;
    width: 100%;
    z-index: 2;
  }
  .loading__title {
    overflow: hidden;
    position: absolute;
    width: fit-content;
    top: 80px;
    left: 6.25%;

    color: $themeWhite;
    font-weight: bold;
    text-transform: uppercase;
    .loading__title-sub {
      color: $themeWhite;
      .char {
        color: $themeWhite;
      }
      display: block;
    }
    h1 {
      &:before {
        content: attr(data-text);
        position: absolute;
        text-align: left;
        box-sizing: border-box;
        top: 0;
        left: 0;
        overflow: hidden;
        // transition: all 400ms ease-in-out;
        color: $themeWhite;
        white-space: nowrap;
        width: 0;
        // animation: textFill 1s ease-in 1.5s forwards;
      }

      transform-origin: left top;
      font-size: 80px;
      white-space: nowrap;
      @media (max-width: 767px) {
        font-size: 40px;
      }
      font-weight: 700;
      line-height: 1;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: transparent;
      -webkit-text-stroke: 1px $themeWhite;
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      -webkit-tap-highlight-color: transparent;
      margin-bottom: 0;
    }
  }
}
