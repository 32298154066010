$heroColor: #ddd9ce;
$themeWhite: #f5f5f5;
$themeBlack: #3c3c3c;

.portfolio {
  &__hero {
    width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    padding: 64px 6.25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // position: sticky;
    top: 0;
  }
  &__title {
    position: absolute;
    text-align: center;
    font-size: 9vw;
    font-weight: bold;
    text-transform: uppercase;
    z-index: 1;
    overflow: hidden;
    color: black;
    @media (max-width: 767px) {
      position: relative;
      margin: 10px auto;
    }
  }
  &__cover {
    width: 700px;
    margin: 0 auto;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      z-index: 1;
      background-color: #d5d7ce;
    }
    img {
      width: 100%;
    }
  }
  &__explore {
    padding: 10px;
    position: absolute;
    bottom: 10vh;
    text-align: center;
    letter-spacing: 1px;
  }
  &__content {
    position: relative;
    z-index: 1;
    background-color: white;
  }
  &__about {
    padding: 200px 6.25% 100px;
    margin-left: 8%;
    margin-bottom: 200px;
    position: relative;
    z-index: 1;
    @media (max-width: 767px) {
      margin-left: 0%;
      margin-bottom: 50px;
      padding: 100px 6.25% 50px;
    }
    &__title {
      margin-bottom: 50px;
      @media (max-width: 767px) {
        margin-bottom: 30px;
      }
      h1 {
        font-size: 80px;
        @media (max-width: 767px) {
          font-size: 40px;
        }
        color: transparent;
        -webkit-text-stroke: 1px $themeBlack;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -webkit-tap-highlight-color: transparent;
      }
      h3 {
        margin-bottom: 15px;
        text-transform: uppercase;
      }
    }
    p {
      margin-top: 0;
      margin-bottom: 40px;
      @media (max-width: 767px) {
        font-size: 16px;
      }
    }

    &__container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas: "content content info";
      @media (max-width: 767px) {
        grid-template-columns: 1fr;
        grid-template-areas: "content" "info";
      }
      &__content {
        grid-area: content;
        line-height: 1.6em;
        a {
          margin-bottom: 30px;
        }
        h4 {
          text-transform: uppercase;
          font-size: 14px;
          letter-spacing: 1px;
          font-weight: bold;
          display: flex;
          align-items: center;
          margin-bottom: 3rem;
        }
      }
      &__info {
        grid-area: info;
        padding-left: 22.5px;
        padding-right: 22.5px;
        margin-left: 8.33333%;
        @media (max-width: 767px) {
          margin-left: 0;
          padding: 0;
        }
        h3 {
          margin-bottom: 10px;
        }
        div {
          margin-bottom: 30px;
        }
      }
    }
  }
  &__graphic {
    background-color: #d5d7ce;
    &__mockup {
      padding: 0 6.25%;
      transform: translateY(-300px);
      @media (max-width: 767px) {
        transform: translateY(-100px);
      }
      img {
        width: 100%;
      }
    }
    &__screenshot {
      padding: 50px 6.25%;
      width: 100%;
      overflow: hidden;
      &__content {
        padding: 200px 0;
        width: 70%;
        position: relative;
        @media (max-width: 767px) {
          padding: 50px 0;
          width: 100%;
        }
        &:nth-child(even) {
          margin-left: auto;
          text-align: right;
          span {
            left: 0;
            right: auto;
            transform: translate(-80%, -20%);
          }
        }
        span {
          position: absolute;
          font-family: "Lora", serif;
          opacity: 0.6;
          color: transparent;
          -webkit-text-stroke: 1px black;
          -webkit-font-smoothing: antialiased;
          text-rendering: optimizeLegibility;
          -webkit-tap-highlight-color: transparent;
          font-size: 20vw;
          font-weight: bold;
          right: 0;
          transform: translate(60%, -20%);
          @media (max-width: 767px) {
            font-size: 80px;
            transform: translateY(-57%) !important;
          }
        }
        h1 {
          position: relative;
          z-index: 1;
          // text-transform: uppercase;
          font-size: 60px;
          @media (max-width: 767px) {
            font-size: 30px;
          }
          color: black;
        }
        img {
          z-index: 1;
          width: 100%;
          max-width: 100%;
          position: relative;
        }
      }
    }
    &__scrollText {
      width: 100%;
      overflow: hidden;
      &-inner {
        // transform: translateX(-50%);
        span {
          font-size: 10vw;
          color: black;
          font-family: "Lora", serif;
          margin: 0 2vw;
          opacity: 0.3;
          font-style: italic;
        }
      }
    }
    &__showcase {
      padding: 200px 6.25%;
      @media (max-width: 767px) {
        padding: 100px 6.25%;
      }
      color: black;
      video {
        width: 80%;
        display: block;
        margin: 0 auto;
        @media (max-width: 767px) {
          width: 100%;
        }
      }
      p {
        text-align: center;
      }
    }
  }
  &__footer {
    .index {
      position: fixed;
      z-index: 10;
      font-family: "Lora", serif;
      color: black;
      bottom: 40px;
      left: 6.25%;
      font-weight: bold;
    }
    .next {
      position: fixed;
      z-index: 10;
      font-family: "Lora", serif;
      color: black;
      bottom: 40px;
      right: 6.25%;
      font-size: 20px;
    }
  }
  &__next {
    padding: 100px 6.25%;
  }
}

.tiles {
  background-color: black;
  // --tiles-height: 52vw;
  --tiles-height: 40vw;
  height: var(--tiles-height);
  position: relative;
  overflow: hidden;

  &__wrap {
    // width: 150%;
    width: 150%;
    --tileswrap-height: var(--tiles-height);
    height: var(--tileswrap-height);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    --tileswrap-height: calc(var(--tiles-height) * 2.2);
    transform: translate3d(-50%, -50%, 0) rotate(22.5deg);
  }

  &__line {
    display: flex;
    &-img {
      --tile-margin: 2vw;
      flex: none;
      height: calc(var(--tileswrap-height) / 3 - (3 * var(--tile-margin) / 2));
      margin: var(--tile-margin);
      background-size: cover;
      background-position: 50% 50%;
      --tile-margin: 1vw;
      width: calc(30% - var(--tile-margin) * 2);
      height: calc(var(--tileswrap-height) / 3 - (4 * var(--tile-margin) / 2));
    }
  }
}
