$themeBlack: #3c3c3c;

.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  padding: 0 6.25%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  @media (max-width: 767px) {
    padding: 0 4.6875vw;
  }
  a {
    font-family: "Lora", serif;
    text-decoration: none;
    font-weight: bold;
    color: $themeBlack;
    letter-spacing: 2px;
    font-size: 14px;
    text-transform: uppercase;
  }
}
