.about {
  // padding: 150px 6.25%;
  padding: 10.313vw 8vw 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  @media (max-width: 767px) {
    padding: 100px 0 0;
    flex-direction: column;
  }

  &__thankyou {
    position: relative;
    span {
      position: absolute;
      bottom: 20px;
      font-size: 30px;
      z-index: 1;
      font-family: "Lora", serif;
      @media (max-width: 767px) {
        font-size: 18px;
      }
    }
  }
  &__hero {
    height: calc(100vh - 10.313vw);
    width: 40%;
    position: relative;
    display: flex;
    transform: translate3d(-8vw, 0, 0);
    pointer-events: none;
    @media (max-width: 767px) {
      width: 100%;
      height: 90vw;
      transform: translate(0, 0);
    }
    img {
      opacity: 0.8;
      width: 100%;
      height: 100%;
      position: absolute;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: top;
      object-position: top;
    }
    .quote {
      position: absolute;
      width: 50vw;
      left: calc(100% - 6.25vw);
      top: 8vw;
      z-index: 2;
      font-size: 66px;
      line-height: 1;
      font-family: "Lora", serif;
      @media (max-width: 767px) {
        bottom: 0;
        left: 0;
        top: unset;
        right: 0;
        margin: auto;
        width: 70%;
        transform: translateY(100%);
        font-size: 7vw;
      }
    }
  }

  &__resume {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    text-decoration: underline;
    margin-top: 1rem;
  }

  &__content {
    width: calc(65% - 11.25vw);
    margin-top: 25.75vw;

    @media (max-width: 767px) {
      width: 100%;
      padding: 0 6.51vw;
    }
  }

  h1 {
    font-size: 40px;
    margin-bottom: 1rem;
  }
  &__section {
    margin-bottom: 2rem;
  }
  &__me {
    flex: 1;
    p {
      margin-right: 30px;
      @media (max-width: 767px) {
        margin-right: 0px;
      }
    }
  }
  &__other {
    flex: 1;
  }
}

.about__other-experience {
  .experience {
    display: flex;
    margin-bottom: 1rem;
    font-size: 14px;
    @media (max-width: 767px) {
      flex-direction: column;
    }
    &__duration {
      flex: 2;
    }
    &__company {
      flex: 2;
    }
    &__position {
      flex: 3;
    }
  }
}

.social {
  a {
    text-decoration: underline;
    display: block;
    margin-bottom: 1rem;
  }
}
