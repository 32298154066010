$themeWhite: #f5f5f5;
$themeBlack: #3c3c3c;

.content {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 100%;
  width: 100vw;
  //   height: calc(100vh - 13rem);
  position: relative;
  align-items: center;
  justify-items: center;

  //   padding: 0 6rem;
  height: 100vh;
  justify-content: center;

  &__action {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    color: black;
    width: 8rem;
    height: 8rem;

    text-align: center;
    padding: 0;
    margin: 0;
    border-radius: 50%;
    position: relative;
    grid-area: 1 / 1 / 3 / 3;
    z-index: 100;
    cursor: pointer;
    background: none;
    font-weight: 700;
    font-size: 1rem;
    @media (max-width: 767px) {
      width: 6rem;
      height: 6rem;
      font-size: 1rem;
    }
    text-transform: uppercase;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: "Lora";
    &:hover {
      color: #000;
    }

    &:focus {
      outline: none;
    }
    &-text {
      position: relative;
      display: block;
    }
  }

  &__slide {
    grid-area: 1 / 1 / 3 / 3;
    position: relative;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(25, 4%);
    grid-template-rows: repeat(25, 4%);
    justify-content: center;
    justify-items: center;
    align-items: center;
    z-index: 1;

    &-item {
      width: 100%;
      height: 100%;
      position: relative;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      &:nth-child(1) {
        grid-area: 11 / 2 / 16 / 9;
      }
      &:nth-child(2) {
        grid-area: 24 / 25 / 19 / 18;
      }
      &:nth-child(3) {
        grid-area: 14 / 18 / 18 / 24;
      }
      &:nth-child(4) {
        grid-area: 3 / 5 / 8 / 12;
      }
      &:nth-child(5) {
        grid-area: 2 / 24 / 6 / 18;
      }
      &:nth-child(6) {
        grid-area: 18 / 1 / 23 / 8;
      }
      &:nth-child(7) {
        grid-area: 20 / 10 / 25 / 17;
      }
      &:nth-child(8) {
        grid-area: 6 / 23 / 11 / 17;
      }
    }

    &-text {
      opacity: 0;
      position: relative;
      height: 100%;
      width: 100%;
      max-height: 700px;
      padding: 0 7vw;
      grid-area: 1 / 1 / 26 /26;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      .content-text {
        margin-top: 150px;
        @media (max-width: 767px) {
          margin-top: 0;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .sub {
          font-size: 16px;
          font-family: "Lora";
          font-weight: bold;
        }
        .title {
          font-size: 9vw;
          font-family: "Lora";
          color: black;
          margin-bottom: 1rem;
        }
      }
    }

    &-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      &inner {
        background-size: cover;
        background-position: 50% 50%;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.glitch {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  &__img {
    pointer-events: none;
    background-size: cover;
    background-position: 50% 50%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translate3d(0, 0, 0);
  }
}
